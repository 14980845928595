import { Route, Routes, useNavigate } from "react-router-dom";
import { useGlobalState } from "../providers/GlobalStateProvider";
import PlantErrorSnackbar from "../lib/components/error-snackbar";
import { SimplifiedTenantReroute } from "./simplified-tenant-reroute";
import LoginPage from "./login-page";
import WithAuthorization from "../lib/components/authed/with-authorization";
import StaRedirect from "./sta-redirect";
import SimplifiedTenantAdaptionPage from "./simplified-tenant-adaption/page";
import Menu, { MenuComopnent } from "./menu";
import ReportWidgetPages from "./report-widget-pages";
import AMAParseWidget from "../lib/AMAParsingWidget";
import { useProjectsQuery } from "../lib/generated/graphql";
import { Button, Input, Link, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";

const GraphqlErrors = () => {
  const state = useGlobalState();

  return (
    <>
      {state.graphQlErrors.map((e: any, i: number) => (
        <PlantErrorSnackbar
          key={i}
          index={i}
          open={true}
          message={e.message}
          details={JSON.stringify(e, null, 2)}
        />
      ))}
    </>
  );
};

const ProjectSelect = () => {
  const { data: projectData } = useProjectsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  const projects = [
    ...(projectData?.projectsV2.edges.map((e) => e.node) || []),
  ];

  return (
    <SimpleGrid gap={4} m={4}>
      <Input
        list="projects"
        id="project"
        name="project"
        placeholder="Choose a project"
        onChange={({ target }) => {
          const project = projects.find((proj) => proj.name === target.value);
          setSelectedProject(project?.id || null);
        }}
      />

      <datalist id="projects">
        {projects.map((project) => (
          <option key={project.id} value={project.name} />
        ))}
      </datalist>
      <Link href={`/projects/${selectedProject}/rapport-print`}>
        <Button isDisabled={!selectedProject}>Go to report</Button>
      </Link>
    </SimpleGrid>
  );
};

const Router = () => {
  return (
    <>
      <Routes>
        <Route element={<LoginPage />} path="/" />

        <Route element={<SimplifiedTenantReroute />} path="/sta/invite" />
        <Route
          element={
            <WithAuthorization requiredScope={"SIMPLIFIED_TENANT_ADAPTION"}>
              <StaRedirect />
            </WithAuthorization>
          }
          path="/projects/:projectId/formul%C3%A4r"
        />
        <Route
          element={
            <WithAuthorization requiredScope={"SIMPLIFIED_TENANT_ADAPTION"}>
              <SimplifiedTenantAdaptionPage />
            </WithAuthorization>
          }
          path="/sta/projects/:projectId/"
        />
        <Route
          element={
            <WithAuthorization requiredScope={"ADMIN"}>
              <Menu>
                <ReportWidgetPages />
              </Menu>
            </WithAuthorization>
          }
          path="/projects/:projectId/rapport-print"
        />
        <Route element={<AMAParseWidget />} path="/ama" />
        <Route
          element={
            <MenuComopnent>
              <ProjectSelect />
            </MenuComopnent>
          }
          path="/projects"
        />
      </Routes>

      <GraphqlErrors />
    </>
  );
};

export default Router;
